<template>
  <!-- 编码:lzq -->
  <div>
    <main>
      <!-- 头部横幅 -->
      <div class="banner">
        <div class="banner1">
          <img v-lazy="require('../assets/imgs/visual/banner.webp')" alt="" />
        </div>
        <!-- 横幅内容区域 -->
        <div class="banner2">
          <h2 class="title">可视化大屏集中管控</h2>
          <p class="introduce">
            集中信息的展示，使信息更灵活、更高效、更形象、更直观的可视化呈现
          </p>
        </div>
      </div>
      <p class="title_h2">响应迅速</p>
      <span class="introduce_span"
        >局部动态的实时刷新，秒级响应，数据庞大也不担心</span
      >
      <div class="photo">
        <img v-lazy="require('../assets/imgs/visual/rectangle1.webp')" />
      </div>
      <!-- 数据整合模块 -->
      <div class="two_photo">
        <div>
          <img
            v-lazy="require('../assets/imgs/visual/group_up38.webp')"
            alt=""
          />
        </div>
        <p>数据整合</p>
        <span
          >支持视屏动画、图形以及实时数据进行统一整合、以提供最全面，最新型的数据</span
        >
      </div>
      <!-- 多终端区域 -->
      <div class="terminal">
        <p>多终端</p>
        <span
          >不同屏幕适配各种拼接大屏，使用浏览器可灵活投放，实时观看最新数据，进行把控</span
        >
        <div>
          <img v-lazy="require('../assets/imgs/visual/rectangle.webp')" />
        </div>
      </div>
      <!-- 自动化智能管理模块 -->
      <div class="two_photo automate">
        <div>
          <img
            v-lazy="require('../assets/imgs/visual/group_up39.webp')"
            alt=""
          />
        </div>
        <p>自动化智能管理</p>
        <span
          >自动完成信息查询，业务办理等任务，解决游客问题，减少人员压力，提供全面服务</span
        >
      </div>
      <p class="title_h2">24小时在线智能客服机器人</p>
      <span class="introduce_span"
        >深入了解景区业务全况，更全面，更深入解决游客的询问</span
      >
      <div class="photo">
        <img v-lazy="require('../assets/imgs/visual/rectangle2.webp')" />
      </div>
    </main>
    <!-- 头部组件 -->
    <Header></Header>
    <!-- 底部组件 -->
    <Footer></Footer>
  </div>
</template>
<script>
// @ is an alias to /src
//引入头部组件
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
export default {
  name: "optimization_algorithm",
  components: {
    Header,
    Footer,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 600px;
  color: #fff;
  position: relative;
  .banner1 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // loading大小
    img[lazy="loading"] {
      width: 200px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      width: 100%;
      height: 100%;
    }
  }
  .banner2 {
    width: 100%;
    height: 600px;
    position: absolute;
    top: 0;
    background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000000 130%);
    z-index: 1;
    .title {
      height: 67px;
      font-size: 48px;
      font-weight: 500;
      line-height: 67px;
      letter-spacing: 3px;
      text-align: left;
      position: absolute;
      left: 361px;
      top: 230px;
    }
    .introduce {
      width: 565px;
      font-size: 24px;
      font-weight: 400;
      line-height: 33px;
      text-align: left;
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
      position: absolute;
      left: 361px;
      top: 321px;
    }
  }
}
.introduce,
.title {
  animation: sport 0.5s;
  transform: translateY(0px);
}
//设置头部文字动画效果
@keyframes sport {
  0% {
    transform: translateY(80px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
//版心
.title_h2 {
  font-size: 40px;
  font-weight: 500px;
  color: #333;
  letter-spacing: 1px;
  margin-bottom: 24px;
  margin-top: 80px;
}
.introduce_span {
  font-size: 20px;
  font-weight: 400px;
  color: #565656;
  letter-spacing: 1px;
}
.photo {
  width: 1200px;
  height: 420px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 60px;
  margin-bottom: 100px;
  // loading大小
  img[lazy="loading"] {
    width: 150px;
  }
  // 实际图片大小
  img[lazy="loaded"] {
    width: 100%;
    height: 100%;
  }
}
.two_photo {
  box-sizing: border-box;
  height: 720px;
  margin-bottom: 80px;
  border-top: 1px solid #fff;
  text-align: left;
  position: relative;
  div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // loading大小
    img[lazy="loading"] {
      width: 200px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      width: 100%;
      height: 100%;
    }
  }
  p {
    position: absolute;
    top: 310px;
    left: 879px;
    font-size: 40px;
    font-weight: 400;
    color: #fff;
    letter-spacing: 1px;
  }
  span {
    position: absolute;
    top: 378px;
    left: 583px;
    right: 581px;
    font-size: 20px;
    font-weight: 400;
    color: #fff;
  }
}
.terminal {
  p {
    font-size: 40px;
    font-weight: 500;
    color: #333333;
    letter-spacing: 1px;
    margin-bottom: 24px;
  }
  span {
    display: block;
    font-size: 20px;
    font-weight: 400;
    color: #565656;
    letter-spacing: 1px;
    margin-bottom: 60px;
  }
  div {
    width: 1200px;
    height: 420px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-bottom: 100px;
    // loading大小
    img[lazy="loading"] {
      width: 150px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      width: 100%;
      height: 100%;
    }
  }
}
.automate {
  span {
    left: 579px;
    right: 577px;
  }
}
</style>
